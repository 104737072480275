.F-Xray__title {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.F-Xray__tag {
    display: inline-block;
    margin: 0 5px;
    padding: 3px 10px;
    font-size: 24px;
    border-radius: 5px;
    background-color: var(--color-teal);
}

.F-Xray__responsive-img {
    display: block;
    width: 100%;
    height: auto;
}
