.wrap-btns {
    display: flex;
    gap: 5px;
}

table.iksweb {
    width: 100%;
    height: auto;
    margin-top: 6px;
    border-collapse: collapse;
    border-spacing: 0;
}

table.iksweb,
table.iksweb td,
table.iksweb th {
    border: 1px solid var(--color-gray-40);
}

table.iksweb tr:first-child td {
    font-weight: 500;
    font-size: 16px;
}

table.iksweb td,
table.iksweb th {
    width: 30px;
    height: 35px;
    padding: 3px;
    font-size: 12px;
}

table.iksweb th {
    color: #fff;
    font-weight: normal;
    background: #347c99;
}

table.iksweb .td-x {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    vertical-align: middle;
}

table.iksweb input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 15px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    border: none;
    background-color: transparent;
}

table.iksweb tr span {
    display: none;
}

table.iksweb tr .changed {
    color: var(--color-teal);
    font-weight: 500
}
