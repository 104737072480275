@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../shared/assets/fonts/inter-v8-latin_cyrillic-regular.woff2') format('woff2'),
        url('../shared/assets/fonts/inter-v8-latin_cyrillic-regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../shared/assets/fonts/inter-v8-latin_cyrillic-500.woff2') format('woff2'),
        url('../shared/assets/fonts/inter-v8-latin_cyrillic-500.woff') format('woff');
}

@font-face {
    font-family: 'Gratimo Grotesk CY';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../shared/assets/fonts/Gratimo Grotesk-Regular-Web.woff2') format('woff2'),
        url('../shared/assets/fonts/Gratimo Grotesk-Regular-Web.woff') format('woff');
}

@font-face {
    font-family: 'Gratimo Grotesk CY';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('../shared/assets/fonts/Gratimo Grotesk-Medium-Web.woff2') format('woff2'),
        url('../shared/assets/fonts/Gratimo Grotesk-Medium-Web.woff') format('woff');
}

:root {
    --base-font-size: 16px;
    --color-black: rgba(22, 28, 39, 1);
    --color-white: rgba(255, 255, 255, 1);
    --color-teal: rgba(20, 214, 188, 1);
    --color-teal-20: rgba(20, 214, 188, 0.2);
    --color-yellow: rgba(237, 214, 8, 1);
    --color-gray: rgba(142, 142, 147, 1);
    --color-gray-80: rgba(142, 142, 147, 0.8);
    --color-gray-40: rgba(142, 142, 147, 0.4);
    --color-gray-20: rgba(142, 142, 147, 0.2);
    --color-dark-blue: rgba(22, 28, 39, 1);
    --color-red: rgba(225, 2, 15, 1);
    --color-red-10: rgba(225, 2, 15, 0.1);
    --container-max-width: 100%;
    --radius: 4px;
    --radius-35: 35px;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    font-size: var(--base-font-size);
    color: var(--color-black);
    font-family: 'Inter', sans-serif;
}

.App {
    min-height: 100vh;
    color: white;
    background-color: #161c27;
}

.App__header {
    display: flex;
    margin-bottom: 50px;
    padding-top: 15vh;
}

.App__wrap-logos {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.App__logo-nnir {
    width: 60px;
    height: auto;
}

.App__wrap-logos svg {
    margin: 0 15px;
}

.App__header-xray {
    margin-bottom: 25px;
    padding-top: 5vh;
}

.App__footer {
    margin-top: 80px;
    padding: 25px 0;
    text-align: center;
    color: var(--color-gray);
    font-size: 14px;
}

.container {
    max-width: var(--container-max-width);
    margin: 0 auto;
    padding: 0 50px;
}

.d-flex {
    display: flex;
}

.gap-5 {
    gap: 5px
}

.gap-10 {
    gap: 5px
}

.align-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.mr-10 {
    margin-right: 10px;
}