.F-Xray-Uploader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    min-height: 500px;
    margin: 0 auto;
    padding: 25px;
    border: 2px dashed var(--color-gray);
    transition: 0.3s;
}

.F-Xray-Uploader:hover {
    border-color: var(--color-teal);
    transition: 0.3s;
}

.F-Xray-Uploader__header {
    margin-bottom: 20px;
    font-size: 20px;
}

.F-Xray-Uploader__preview {
    display: block;
    width: auto;
    height: 400px;
    margin-bottom: 50px;
}

.F-Xray-Uploader__caption {
    font-size: 12px;
    text-align: center;
}

.F-Xray-Uploader__or {
    margin: 35px 0;
    font-size: 20px;
    font-weight: 400;
    color: var(--color-gray);
}

.F-Xray-Uploader__keys {
    font-size: 24px;
    text-align: center;
}

.F-Xray-Uploader__spin {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid var(--color-teal);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
