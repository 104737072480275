.F-Xray-Result__grid {
    display: grid;
    grid-template-columns: minmax(500px, 1fr) minmax(500px, 1fr);
    grid-gap: 20px;
}

.F-Xray-Result__response-data {
    font-size: 14px;
}

.F-Xray-Result__response-data h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
}

.F-Xray-Result__response-data-text {
    padding: 8px;
    font-size: 16px;
    line-height: 1.6;
    border-radius: 8px;
    background-color: var(--color-gray-20);
}

.F-Xray-Result__wrap-files {
    display: flex;
    flex-direction: column;
}

.F-Xray-Result__wrap-files .UI-Button {
    margin: 0 auto;
    text-decoration: none;
}

.F-Xray-Result__wrap-files img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
