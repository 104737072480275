.F-Auth {
    max-width: 350px;
    margin: 0 auto;
    padding: 30px 33px;
    border-radius: 25px;
    border: 1px solid var(--color-gray);
}

.F-Auth__footer-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
