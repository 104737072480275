.UI-Button {
    position: relative;
    display: block;
    padding: 20px 45px;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 35px;
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
}

.UI-Button--primary {
    color: var(--color-white);
    background-color: var(--color-teal);
}

.UI-Button--ghost {
    color: var(--color-white);
    border: 1px solid var(--color-teal);
}

.UI-Button--link {
    padding: 0;
    color: var(--color-teal);
    border: none;
}

.UI-Button--small {
    padding: 10px 25px;
    border-radius: 6px;
}

.UI-Button__popover {
    position: absolute;
    top: -5px;
    left: calc(50% - 20px);
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 5px;
    background-color: var(--color-gray-40);
    visibility: hidden;
    opacity: 0;
    transition: .3s;
}

.UI-Button:hover .UI-Button__popover {
    top: -28px;
    visibility: visible;
    opacity: 1;
    transition: .3s;
}